
import React from 'react'
import { css } from '@emotion/css'
import tw, { GlobalStyles } from "twin.macro"

const tailwindStyle = css`
@tailwind base;
@tailwind components;
@tailwind utilities;
`;

import '../../../styles/cssfonts/xpress.css'

const ParagraphStyle = css`

    p {
        font-family: "Xpress", sans-serif;
        
    }
`;

const composedStyle = css`
    ${tailwindStyle};
    ${ParagraphStyle};
`;


export function CoursePromoParagraph(props){
    return (
        <div className={composedStyle}>
            <GlobalStyles />
            <div className="fullscreen" tw="bg-[#2A160C] md:bg-elegant-black">
                <div className="maxlaptop" tw="flex pb-20 max-w-screen-xl mx-auto">
                    <p tw="text-elegant-white justify-center text-center text-xl pt-10 px-10">Although it has been a true joy to put together the "Data Visualization in the Metaverse course", all
                     good things must come to an end. The course is no longer being offered for sale. For people who have already purchased that have questions, please
                      send me an email at <a tw="underline "href="mailto:info@whiteowleducation.com">info@whiteowleducation.com</a> .
 </p>
                </div>
            </div>
        </div>
    )
}
