import React, { useEffect, useState } from 'react'
// import styled from "styled-components";
import { Helmet } from 'react-helmet'

import { graphql, useStaticQuery } from 'gatsby'
import { Layout } from '../../components/common'
import { LargeCoursePromo } from '../../components/styledcomponents/LargeCoursePromo'

import { VisTOC } from '../../components/styledcomponents/courses/VisTableOfContents'
import { MadeWithUnrealBanner } from '../../components/pagecomponents/metaverse/MadeWithUnreal'

import '../../styles/typography.css'

// in-line css
import { GlobalStyles } from "twin.macro"
import { css } from '@emotion/css'

import { SubHeader } from '../../components/common/nav/Subnav'

import { MakeAppBanner } from '../../components/pagecomponents/metaverse/MakeApp'
import { CoursePromoParagraph } from '../../components/pagecomponents/metaverse/CoursePromoParagraph'
import { AboutMetaverseBanner } from '../../components/pagecomponents/metaverse/aboutsection/AboutMetaverse'
import { UnrealMetaverseBanner } from '../../components/pagecomponents/metaverse/unrealsection/UnrealMetaverse'
import { Shutter } from '../../components/common/design/shutter'
import { VideoPlayer } from '../../components/common/design/VideoPlayer'
import { OverviewBanner } from '../../components/pagecomponents/metaverse/overviewsection/Overview'

import { StaticImage } from "gatsby-plugin-image"

import { offsetAnchor } from "../../components/pagecomponents/metaverse/MetaversePageOffset"
import { SeeDifferenceBanner } from '../../components/pagecomponents/metaverse/difference/SeeDifference'


// 2022-04-24 attempted fix of write EPIPE


const tailwindStyle = css`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
`;

const MetaverseStyle = css`
    h1 {
        font-size: 2.8rem;
        font-weight: 700;
        font-family: "NewsGothic";
    }

    h3 {
        font-size: 1.15rem;
        font-weight: 500;
    }

    /* used in curriculum also */


`;

const composedStyle = css`
    ${tailwindStyle};
    ${MetaverseStyle};
`;






const DataVisMetaversePage = ({data}) => {

    const [pageInitialLoad, setPageInitialLoad] = useState(false);

    useEffect(() => {
        if (typeof window === 'undefined'){
            // console.log("window undef")
            return;
        }
        
        // https://stackoverflow.com/questions/54942047/how-to-get-previous-url-in-react-gatsby
        // In gatsby, location is not available in server side rendering
        // The key is to check if window is available first

        if( window.location.hash.length !== 0 && !pageInitialLoad){
            const hashstring = window.location.hash.substring(1);
            
            window.setTimeout(function() {
                offsetAnchor(hashstring);
            }, 0);
           
        }
        setPageInitialLoad(true);
    })


    


    const priceQuery = graphql`
    query {
        gateway {
            course(_id: 1) {
                course_price
            }
        }
    }
    `

    const price_data = useStaticQuery(priceQuery)

    const button_text = `ENROLL NOW FOR \$${price_data.gateway.course.course_price}!`

    // http://whiteowleducation.teachable.com/purchase?product_id=3617725&_ga=2.60499693.1727088930.1638938520-574767650.1636469471
    // https://courses.whiteowleducation.com/purchase?product_id=3617725&amp;_ga=2.60499693.1727088930.1638938520-574767650.1636469471
    // "https://whiteowleducation.teachable.com/purchase?product_id=4118246"

    const largeCoursePromoData = {
        "headline": "Data Visualization in the Metaverse",
        "subheadline": "Learn how to use Unreal Engine, Cesium, and other tech to examine Census Data.",
        "button" : {
            "href": "https://www.whiteowleducation.com/cart/checkout/",
            "text": button_text
        }
    
    }


    // ----------------------- Twitter and FB Card information

    const siteName = "White Owl Education"
    const nameOfThisFile = "/courses/data-visualization-metaverse/"
    const title = "Data Visualization in the Metaverse"
    const description = "Learn how to use Unreal Engine, Cesium, Python, and C++ to examine Census Data."

    const breadcrumbLD = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.whiteowleducation.com/"
            },
            {
                "@type": "ListItem",
                "position":2,
                "name": "Courses",
                "item": "https://www.whiteowleducation.com/courses/"
            },
            {
                "@type": "ListItem",
                "position":3,
                "name": "Data Visualization in the Metaverse",
                "item": "https://www.whiteowleducation.com/courses/data-visualization-metaverse/"
            }             
        ]
    }



    // TODO - ERR - card image MUST BE REPLACED
    const cardImage = "https://whiteowleducation.com/data-vis-metaverse.png"

    // https://stackoverflow.com/questions/17534661/make-anchor-link-go-some-pixels-above-where-its-linked-to
    // Set the offset when entering page with hash present in the url




    

    return (
        <Layout
            pageInfo={{
                "title": `${title}`, 
                "description": `${description}`, 
            }}
            pathname={nameOfThisFile}
            twitterInfo={{"title": `${title}`, 
                          "card": "summary_large_image",
                          "description": `${description}`,
                          "imgsrc": `${cardImage}`,
                          "site": "@whiteowled"
                        }}
            fbInfo={{
                    "image": `${cardImage}`,
                    "description": `${description}`,
                    "title": `${title}`,
                    "type": "website",
                    "site_name": `${siteName}`
            }}
        >
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(breadcrumbLD, undefined, 4)}
            </script>
        </Helmet>
            <div className={composedStyle}>
                <GlobalStyles />
                
                {/* <div className="subhead-sticky" tw="sticky top-0 z-20">
                    <SubHeader
                        className="sub-header"
                        buttonhref={largeCoursePromoData.button.href}
                    />
                </div> */}

                
                {/* <MakeAppBanner className="diffmatter" 
                                price={price_data.gateway.course.course_price}
                                buttonhref={largeCoursePromoData.button.href}
                /> */}

                <CoursePromoParagraph />

                
                {/* <SeeDifferenceBanner
                    className='seedifference'
                /> */}
            
{/* 
                <UnrealMetaverseBanner
                    className="LearnBuildUnreal"
                />

                <OverviewBanner
                    id="overview"
                    className="courseoverview"
                /> */}
        
                {/* <AboutMetaverseBanner
                    id="author"
                    className="aboutmeta"
                /> */}
            
                {/* <div id="lessons" tw="mt-6">
                    <h1 tw="text-center">Course Curriculum</h1>
                    <article className="content" style={{ textAlign: `center` }}>
                        <section tw="mb-8" className="content-body">
                            <VisTOC />
                        </section>
                    </article>
                </div> */}
                
            </div>
        </Layout>
    )
}

export default DataVisMetaversePage
