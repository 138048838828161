export const offsetAnchor = (key: string) => {
        
    // This function makes sure subnav does not cover top part of section
    // Offset anchor is called after the page jump

    // START HERE - RAB - 
    // https://stackoverflow.com/questions/17534661/make-anchor-link-go-some-pixels-above-where-its-linked-to

    switch(key){
        case "author": {
            // If the link has a hash on it
            if (location.hash.length !== 0) {
                // Scroll to the hash and then scroll up
                window.scrollTo(window.scrollX, window.scrollY - 100);
              }
            break;
        }
        case "overview": {
            
            if (location.hash.length !== 0) {
                // Scroll to the hash and then scroll up
                window.scrollTo(window.scrollX, window.scrollY - 100);
              }

            break;
        }
        case "lessons":{
            if (location.hash.length !== 0) {
                // Scroll to the hash and then scroll up
                window.scrollTo(window.scrollX, window.scrollY - 100);
              }

            break;
        }
    }
}